import React from "react";

export default function Milestone() {
  return (
    <section className="milestone-section  our-product-section" id="about">
      <p className="mb-0 title-main-section">
        <span>Our</span> Milestone
      </p>
      <div className="milestone-wrapper w-90 m-auto">
        <div className="row margin-top-60">
          <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12">
            <div className="left-milestone-wrapper">
              <p className="mb-0 milestone-text">
                We are a group of passionate and committed people with diverse
                backgrounds, teamed up to solve the problems of SMEs from end to
                end. SMEs are the growth engines of developing countries and
                contribute a lot to GDP but SMEs lack the required resources in
                accessing the market, hiring the talent, making the
                product-market fit, etc. By providing 360-degree solutions to
                the SMEs we work towards unlocking the true potential of SMEs
                and help them to reap the benefits of technologyt
              </p>
              <div className="d-flex align-items-center milestone-btn-wrapper">
                <a href="#product">
                  <button className="blue-btn-milestone">Learn More</button>
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 right-box-section-milestone">
            <div className="d-flex align-items-center ">
              <div className="box-card-milestone">
                <p className="mb-0 title-mile">10</p>
                <p className="mb-0 pro-text-mile">Projects done</p>
              </div>
              <div className="box-card-milestone">
                <p className="mb-0 title-mile">250+</p>
                <p className="mb-0 pro-text-mile">Postive Reviews</p>
              </div>
            </div>
            <div className="d-flex align-items-center ">
              <div className="box-card-milestone mt-5">
                <p className="mb-0 title-mile">15</p>
                <p className="mb-0 pro-text-mile">Top Professionalss</p>
              </div>
              <div className="box-card-milestone mt-5">
                <p className="mb-0 title-mile">20</p>
                <p className="mb-0 pro-text-mile">Happy clients</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
