import logo from "./assets/logo.png";
import { Nav, Navbar, Image } from "react-bootstrap";
import { useState } from "react";

export default function NavBar() {
  const [menuItem, setMenuItem] = useState([
    { id: "#home", title: "Home" },
    { id: "#services", title: "Services" },
    { id: "#product", title: "Product" },
    // { id: "#blog", title: "Blog" },
    // { id: "#hr", title: "Hire Resources" },
  ]);
  const [activeMenu, setActiveMenu] = useState("#home");
  const hanldeSelect = (active) => {
    setActiveMenu(active);
  };
  return (
    <section className="navbarSection" sticky="top">
      <Navbar className=" w-90 m-auto" sticky="top" expand="lg" variant="dark">
        <Navbar.Brand href="#home">
          <Image src={logo} className="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            activeKey={activeMenu}
            onSelect={(a) => hanldeSelect(a)}
            className="ml-auto"
          >
            {menuItem.map((item) => (
              <Nav.Link href={item.id} key={item.id}>
                {item.title}{" "}
              </Nav.Link>
            ))}
          </Nav>
          <Nav.Link href="#footer">
            <div className="right-progress">
              <div className="right-btn-wrapper">
                <button className="contact-us">Contact Us</button>
              </div>
            </div>
          </Nav.Link>
        </Navbar.Collapse>
      </Navbar>
    </section>
  );
}
