import React from "react";
import vikir from "./assets/vikirLogo.png";
import mobile from "./assets/mobile.png";
import track from "./assets/track.png";
import manage from "./assets/manage.png";
import app from "./assets/app.png";
import network from "./assets/network.png";
import payment from "./assets/payment.png";
import mobileBg from "./assets/phone.png";
import postJob from "./assets/post-job.png";
import hr from "./assets/hr.png";
import upload from "./assets/upload.png";
import verify from "./assets/verify.png";
import konnect from "./assets/konnect.png";
import angularIcon from "./assets/angular-icon.png";
import reactIcon from "./assets/react-icon.png";
import appDev from "./assets/app-dev.png";
import mean from "./assets/mean.png";

export default function OurProduct() {
  return (
    <div id="product" className="pt-5">
      <section className="pt-5">
        <p className="mb-0 title-main-section">
          <span>Our</span> Product
        </p>
        <div className="w-90 m-auto">
          <div className="row margin-top-60">
            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
              <div className="vikir-wrapper">
                <img src={vikir} />
              </div>
            </div>
            <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12">
              <div className="product-right-wrapper">
                <p className="mb-0 pro-title">
                  All your Business needs in one App
                </p>
                <p className="pro-text-small">
                  vikir is a B2B marketplace for business opportunities in
                  India. The vikir platform facilitates SME’s to find trusted
                  business partners while maintaining customers and operations
                  through one seamless digital experiencee.
                </p>
              </div>
              <div className="d-flex align-items-center">
                <div className="pro-btn">
                  <span>
                    <img src={mobile} />
                  </span>
                  <span className="pro-text-item">Mobile Application</span>
                </div>
                <div className="pro-btn">
                  <span>
                    <img src={track} />
                  </span>
                  <span className="pro-text-item">Track enquiries</span>
                </div>
                <div className="pro-btn">
                  <span>
                    <img src={manage} />
                  </span>
                  <span className="pro-text-item">Manage Employees</span>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div className="pro-btn">
                  <span>
                    <img src={app} />
                  </span>
                  <span className="pro-text-item"> Application for Emp..</span>
                </div>
                <div className="pro-btn">
                  <span>
                    <img src={network} />
                  </span>
                  <span className="pro-text-item">Manage Network </span>
                </div>
                <div className="pro-btn">
                  <span>
                    <img src={payment} />
                  </span>
                  <span className="pro-text-item">Payment Book</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="our-product-section row-reverse">
        <div className="w-90 m-auto">
          <div className="row margin-top-60">
            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
              <div className="vikir-wrapper">
                <img src={mobileBg} />
              </div>
            </div>
            <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12">
              <div className="product-right-wrapper">
                <p className="mb-0 pro-title">Vikir Job</p>
                <p className="pro-text-small">
                  Skilling + Naukari
                  <br />
                  Find jobs in more than 400 job categories near to you.
                </p>
              </div>
              <div className="d-flex align-items-center">
                <div className="pro-btn">
                  <span>
                    <img src={postJob} />
                  </span>
                  <span className="pro-text-item">Post a job quickly</span>
                </div>
                <div className="pro-btn">
                  <span>
                    <img src={hr} />
                  </span>
                  <span className="pro-text-item">
                    Chat directly & Schedule...
                  </span>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div className="pro-btn">
                  <span>
                    <img src={upload} />
                  </span>
                  <span className="pro-text-item"> Upload document</span>
                </div>
                <div className="pro-btn">
                  <span>
                    <img src={verify} />
                  </span>
                  <span className="pro-text-item">
                    verified & skilled jobseeke...
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="our-product-section">
        <div className="w-90 m-auto">
          <div className="row margin-top-60">
            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
              <div className="vikir-wrapper">
                <img src={konnect} />
              </div>
            </div>
            <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12">
              <div className="product-right-wrapper">
                <p className="mb-0 pro-title">Software Development Services</p>
                <p className="pro-text-small">
                  We provide dynamic & custom web, mobile applications
                  development services and solutionse.
                </p>
              </div>
              <div className="d-flex align-items-center">
                <div className="pro-btn">
                  <span>
                    <img src={angularIcon} />
                  </span>
                  <span className="pro-text-item">Angular Development</span>
                </div>
                <div className="pro-btn">
                  <span>
                    <img src={reactIcon} />
                  </span>
                  <span className="pro-text-item">React Js Development</span>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div className="pro-btn">
                  <span>
                    <img src={appDev} />
                  </span>
                  <span className="pro-text-item">
                    {" "}
                    Application Development
                  </span>
                </div>
                <div className="pro-btn">
                  <span>
                    <img src={mean} />
                  </span>
                  <span className="pro-text-item">
                    MEAN Stack App Development.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
