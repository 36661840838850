import React from "react";
import { trustedByComp } from "./JsonData";

export default function Trusted() {
  return (
    <section className="trusted-section mt-5 ">
      <div className="trusted-wrapper">
        <p className="mb-0 trusted-text">
          Line of partners/companies AIRSME has worked with
        </p>
        <div className="d-flex align-items-center justify-content-center margin-top-36">
          {trustedByComp.map((logo) => {
            return (
              <div className="brand-logo" key={logo.id}>
                <img src={logo.logo} />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}
