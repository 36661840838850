import React from 'react'
import rightImgBanner from "./assets/right-img-banner.png";
import iconMail from "./assets/Icon_mail.png";
import bxTime from "./assets/bx_time.png";

export default function Home() {
  return (
    <section className="mt-4 pt-5" id="home">
    <div className=" w-90 m-auto">
      <div className="row">
        <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12">
          <div className="banner-left-wrapper">
            <p className="m-0 banner-main-text">
              Boost Productivity{" "}
              <span className="black-font">With Simple Business</span>{" "}
              <br />
              Solutions
            </p>
            <p className="mb-0 banner-small-text">
              Are you looking to outsource your project? Then
              <br /> look no further as AIRSME has all the right tools
              <br /> to help you.
            </p>
            <div className="d-flex align-items-center margin-top-36">
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
          <div className="left-banner-wrapper">
            <div className="right-banner-img">
              <img src={rightImgBanner} />
            </div>
            <div className="thank-you-wrapper">
              <div className="d-flex align-items-center set-design-wrapper">
                <div className="message-icon">
                  <img src={iconMail} />
                </div>
                <div className="thank-you-text">
                  <p className="mb-0 thank-you-msg">
                    We Thank You For Your Visit
                  </p>
                  <p className="m-0">
                    <span>
                      <img src={bxTime} />
                    </span>
                    <span className="just-now">Just Now</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}
