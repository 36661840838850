import React from "react";
import rightIcon from "./assets/icon.png";
import locationIcon from "./assets/location-icon.png";
import msg from "./assets/msg.png";
import phoneIcon from "./assets/phone-icon.png";
import linkedin from "./assets/linkedin.png";
import { useFormik } from "formik";
import axios from "axios";

const initialValue = {
  name: "",
  companyName: "",
  email: "",
  position: "",
  message: "",
};
const handleValidate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = " Required *";
  }
  if (!values.email) {
    errors.email = "Required *";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  if (!values.position) {
    errors.subject = " Required *";
  }
  if (!values.companyName) {
    errors.subject = " Required *";
  }
  if (!values.message) {
    errors.message = " Required *";
  }
  return errors;
};

export default function Footer() {
  const contactForm = useFormik({
    initialValues: initialValue,
    validate: handleValidate,
    onSubmit: (values) => {
      handleApiCall(values);
    },
  });
  const handleApiCall = async (value) => {
    let newObj = {
      description: value.message,
      fullName: value.name,
      title: value.position,
      email: value.email,
      companyName: value.companyName,
    };
    await axios.post(
      "http://3.108.187.7:3000/support/contact_request_services",
      newObj
    );
    contactForm.resetForm();
  };
  return (
    <section className="footer-section" id="footer">
      <div className="progress-section">
        <div className="progress-wrapper">
          <div className="d-flex align-items-center justify-content-between">
            <div className="left-progress">
              <p className="mb-0 progress-title">
                Let's Create Progress Together
              </p>
              <div className="d-flex align-items-center margin-top-18">
                <div className="tech-wrapper">
                  <span className="right-icon">
                    <img src={rightIcon} />
                  </span>
                  <span className="tech-text">Angular Development</span>
                </div>
                <div className="tech-wrapper margin-left-30">
                  <span className="right-icon">
                    <img src={rightIcon} />
                  </span>
                  <span className="tech-text">Application Development</span>
                </div>
                <div className="tech-wrapper margin-left-30">
                  <span className="right-icon">
                    <img src={rightIcon} />
                  </span>
                  <span className="tech-text">React Js Development</span>
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </div>
      <div className="w-90 m-auto">
        <div className="d-flex flex-wrap ">
          <div className="col-xl-5 col-lg-5 col-md-6 col-sm-12">
            <p className="mb-0 pro-develop">Professional Development</p>
            <p className="mb-0 pro-text-develop">
              Are you looking to outsource your project? Then look no further as
              AIRSME has all the right tools to help you.
            </p>
            <div className="contact-details margin-top-36">
              <div className="d-flex align-items-center contact-wrapper-footer">
                <span className="icon-footer">
                  <img src={locationIcon} />
                </span>
                <span className="footer-contact-text">
                  59, 1st floor, 1st Avenue,
                  <br />
                  Teachers colony, HSR layout Bangalore 560034
                </span>
              </div>
              <div className="d-flex align-items-center contact-wrapper-footer margin-top-36">
                <span className="icon-footer">
                  <img src={msg} />
                </span>
                <span className="footer-contact-text">admin.a@airsme.in</span>
              </div>
              <div className="d-flex align-items-center contact-wrapper-footer margin-top-36">
                <span className="icon-footer">
                  <img src={phoneIcon} />
                </span>
                <div className="phone-span">
                  <span className="footer-contact-text">+91-9108319254</span>
                  <span className="footer-contact-text mt-1">
                    +91-96942 19254
                  </span>
                </div>
              </div>
              <div className="d-flex align-items-center contact-wrapper-footer margin-top-36">
                <span className="icon-footer">
                  {/* <img src={phoneIcon} /> */}
                </span>
                <div className="phone-span">
                  <span className="footer-contact-text">
                    <a
                      href="https://www.linkedin.com/company/airsme-technology-pvt-ltd/"
                      target="_blank"
                    >
                      <img src={linkedin} />
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 res-margin-top-30">
            <div className="middle-wrapper">
              <p className="mb-0 middle-title">Get in Touch With Us</p>
              <form onSubmit={contactForm.handleSubmit}>
                <div className="contact-form margin-top-30">
                  <div className="d-flex align-items-center justify-content-between">
                    <label className="contact-detail-form mb-0">
                      First Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      className="input-box-footer"
                      placeholder=""
                      onChange={contactForm.handleChange}
                      value={contactForm.values.name}
                    />
                  </div>
                  <div className="d-flex align-items-center margin-top-30 justify-content-between">
                    <label className="contact-detail-form mb-0">Company</label>
                    <input
                      type="text"
                      name="companyName"
                      className="input-box-footer"
                      placeholder=""
                      onChange={contactForm.handleChange}
                      value={contactForm.values.companyName}
                    />
                  </div>
                  <div className="d-flex align-items-center margin-top-30 justify-content-between">
                    <label className="contact-detail-form mb-0">Position</label>
                    <input
                      type="text"
                      name="position"
                      className="input-box-footer"
                      placeholder=""
                      onChange={contactForm.handleChange}
                      value={contactForm.values.position}
                    />
                  </div>
                  <div className="d-flex align-items-center margin-top-30 justify-content-between ml-auto">
                    <label className="contact-detail-form mb-0">Email</label>
                    <input
                      type="text"
                      className="input-box-footer"
                      placeholder=""
                      name="email"
                      onChange={contactForm.handleChange}
                      value={contactForm.values.email}
                    />
                  </div>
                  <div className="d-flex align-items-center margin-top-30 justify-content-between">
                    <label className="contact-detail-form mb-0">Message</label>
                    <input
                      type="text"
                      className="input-box-footer"
                      name="message"
                      placeholder=""
                      onChange={contactForm.handleChange}
                      value={contactForm.values.message}
                    />
                  </div>
                  <div className="send-msg">
                    <button>Send Message</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
