import React from "react";
import rightArrow from "./assets/arrow-right.png";
import { ourServices } from "./JsonData";

export default function OurServices() {
  return (
    <section className="w-90 m-auto mt-5 pt-5" id="services">
        <p className="mb-0 title-main-section  ">
          <span>Our</span> Services
        </p>
        <p className="mb-0 why-text">
          AIRSME provides a complete solution for end-to-end web and mobile app
          development services. We collaborate with companies worldwide to craft
          unique and custom solutions and integrate cutting-edge
          business-changing systems. We partner with organisations to create
          innovative ideas to take their businesses to the next level.
        </p>
        <div className="row container  m-auto flex-wrap margin-top-30">
          {ourServices.map((iteam) => {
            return (
              <div
                className="col-xl-6 col-lg-6 col-md-12 col-sm-12 margin-top-30"
                key={iteam.id}
              >
                <div className="box-wrapper-service">
                  <div className="service-img">
                    <img src={iteam.logo} />
                  </div>
                  <p className="mb-0 service-name">{iteam.heading}</p>
                  <p className="mb-0 service-text">{iteam.discription}</p>
                  {/* <div className="read-more">
                        <span>Read More</span>
                        <span><img src={rightArrow} /></span>
                    </div> */}
                </div>
              </div>
            );
          })}
        </div>
    </section>
  );
}
