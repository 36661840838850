import React from 'react'
import choose from "./assets/choose.png"
import reasonable from "./assets/reasonable.png"
import expPro from "./assets/exp-pro.png"
import cusFeed from "./assets/cus-feed.png"
import process from "./assets/process.png"
export default function WhyChooseUs() {
  return (
    <section className="why-choose-section" id="whyus">

        <div className="w-90 m-auto">
            <div className="row ">
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    <div className="why-img">
                        <img src={choose} />
                    </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    <p className="mb-0 title-main-section"><span>Why </span> Choose Us?</p>
                    <p className="mb-0 why-text">With so many software solution companies out there, what makes us
                        different? Here are a few reasons why.
                    </p>
                    <div className="why-wrapper margin-top-30">
                        <div className="d-flex align-items-center">
                            <div className="why-icon">
                                <img src={reasonable} />
                            </div>
                            <div className="box-text-wrapper">
                                <p className="mb-0 box-title">Reasonable Price</p>
                                <p className="mb-0 box-content">Flexible business models indicate cost-effectiveness. Hiring
                                    and onboarding process is made easy.</p>
                            </div>
                        </div>
                    </div>
                    <div className="why-wrapper margin-top-30">
                        <div className="d-flex align-items-center">
                            <div className="why-icon">
                                <img src={expPro} />
                            </div>
                            <div className="box-text-wrapper">
                                <p className="mb-0 box-title">Experienced Professional</p>
                                <p className="mb-0 box-content">Dedicated and skilled developers and designers to
                                    efficiently manage the projects.</p>
                            </div>
                        </div>
                    </div>
                    <div className="why-wrapper margin-top-30">
                        <div className="d-flex align-items-center">
                            <div className="why-icon">
                                <img src={cusFeed} />
                            </div>
                            <div className="box-text-wrapper">
                                <p className="mb-0 box-title">Customer Feedback</p>
                                <p className="mb-0 box-content">We believe in teamwork and therefore our customers’ ideas
                                    are valuable for us too.</p>
                            </div>
                        </div>
                    </div>
                    <div className="why-wrapper margin-top-30">
                        <div className="d-flex align-items-center">
                            <div className="why-icon">
                                <img src={process} />
                            </div>
                            <div className="box-text-wrapper">
                                <p className="mb-0 box-title">Process Oriented</p>
                                <p className="mb-0 box-content">Best in className processes ensure maximum efficiency and,
                                    continuous learning and self organisation.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
