import "./App.css";
import NavBar from "./NavBar";
import Home from "./Home";
import Trusted from "./Trusted";
import OurServices from "./OurServices";
import WhyChooseUs from "./WhyChooseUs";
import OurProduct from "./OurProduct";
import Milestone from "./Milestone";
import Footer from "./Footer";
import AOS from "aos";
import "aos/dist/aos.css";


function App() {
  AOS.init();
  return (
   <>
      <NavBar />
      <Home id="home"/>
      <Trusted />
      <OurServices id="services" />
      <WhyChooseUs id="whyus"/>
      <OurProduct id="product"/>
      <Milestone  id="about"/>
      <Footer id="footer"/>
      </>
  );
}

export default App;
