
import tnt from "./assets/tnt.png";
import vision from "./assets/vision.jpeg";


import angular from "./assets/angular.png";
import mix from "./assets/mix.png";
import ionic from "./assets/ionic.png";
import react from "./assets/react.png";

export const trustedByComp = [
  {
    id: "trustedByComp1",
    logo: tnt,
  },
  {
    id: "trustedByComp2",
    logo: vision,
  },
  
];

export const ourServices = [
  {
    id: "ourServices1",
    logo: angular,
    heading: "Angular Development",
    discription:
      "Airsme is a leading Angular JS (Java Script-based) development company that specializes in creating high-quality, and browser-centric applications. Our experienced, expert developers are very adept in designing all the features of the framework. Our forte is building glitch-free, expressive, and readable applications using Angular JS, at the most affordable prices",
  },  
  {
    id: "ourServices2",
    logo: mix,
    heading: "MEAN Stack App Development",
    discription:
      "It helps you build fast, robust, and maintainable production web applications using MongoDB, Express, Angular, and Node.js. Mean Stack offers Fast MVP development and scalability.",
  },
  {
    id: "ourServices3",
    logo: ionic,
    heading: "Application Development",
    discription:
      "Airsme, India is one of the most renowned  mobile application development companies  that began  making Windows, Android and iOS apps long back. We have always provided best  service in mobile & IOS app developments on outsourcing basis  till date. When you choose Airsme, rest assured, you are partnering with the most efficient development company that has been planning and developing feature-rich mobile applications for years in successful ways.",
  },
  {
    id: "ourServices4",
    logo: react,
    heading: "React Js Development",
    discription:
      "Our offshore React JS developers build interactive UI/UX design for real-time user experience, giving you the high-speed experience in terms of usability. Airsme developers have been catering to this field  for years, bringing this latest technology to your doorsteps. Our React JS programmers provide quick deployment of new React projects, and deliver them in the market with reliability, absolutely error-free.",
  },
];
